import React from "react";
import feedbackImg1 from "../assets/feedback-img-1.svg";
import feedbackImg2 from "../assets/feedback-img-2.svg";

const Feedbacks = () => {
  return (
    <div className="text-[#545454]">
      <header className="flex items-center justify-center">
        <p className="capitalize font-bold text-4xl mb-20 text-center">
          What People are saying
        </p>
      </header>
      <div className="flex flex-col gap-4 lg:gap-3">
        <main className="flex flex-col items-center gap-8 lg:gap-0 lg:flex-row bg-[#F3F1BD] rounded-[1.75rem]  py-14 lg:pt-24 lg:pb-32 relative">
          <div className="flex flex-col gap-8 px-6 md:pl-28">
            <p className="font-semibold text-[1.2rem]">
              I’m thrilled with savings on my gas and{" "}
              <br className="hidden lg:inline" /> generator service bills and
              the peace of <br className="hidden lg:inline" /> mind that comes
              with having power even <br className="hidden lg:inline" /> during
              outages
            </p>
            <div className="flex flex-col gap-2 justify-center md:justify-normal">
              <p className="font-extrabold text-base">Fehintoluwa Dahunsi</p>
              <p className="font-bold text-sm">10kva Solar Hybrid System</p>
            </div>
          </div>
          <img
            src={feedbackImg1}
            alt=""
            className="lg:absolute lg:right-20 lg:bottom-10 w-72 lg:w-auto  "
          />
        </main>
        <main className="flex flex-col items-center gap-4 lg:gap-0 lg:flex-row bg-[#D8FFBA] rounded-[1.75rem] py-14 lg:pt-24 lg:pb-32 relative">
          <div className="flex flex-col gap-8 px-6 md:pl-28">
            <p className="font-semibold text-[1.2rem]">
              From start to finish, the team was{" "}
              <br className="hidden lg:inline" /> professional, knowledgeable,
              and helpful in <br className="hidden lg:inline" /> designing an
              off grid system that was <br className="hidden lg:inline" />{" "}
              perfect for our fulfilment centres, and there{" "}
              <br className="hidden lg:inline" /> has never been a record of
              power cut at any <br className="hidden lg:inline" /> point in time
            </p>
            <div className="flex flex-col gap-2">
              <p className="font-extrabold text-base">Sendme Food Technology</p>
              <p className="font-bold text-sm">10kva Off grid System</p>
            </div>
          </div>
          <img
            src={feedbackImg2}
            alt=""
            className="lg:absolute lg:right-20 lg:bottom-20 w-72 lg:w-auto"
          />
        </main>
      </div>
    </div>
  );
};

export default Feedbacks;
