import React, { useState, useEffect } from "react";
import heroImg1 from "../assets/hero-img-1.jpg";
import heroImg2 from "../assets/hero-img-2.jpg";
import heroImg3 from "../assets/hero-img-3.jpg";

const images = [
  {
    src: heroImg1,
    text: "Giving communities\nAccess to Renewable Solar Energy",
    textColor: "text-[#FFF]",
  },
  {
    src: heroImg2,
    text: "Procure Renewable Energy\nequipment with flexible payment plan",
    textColor: "text-[#5B5B5B]",
  },
  {
    src: heroImg3,
    text: "Access Quality\nSolar Energy Solution",
    textColor: "text-[#5B5B5B]",
  },
];

const HeroSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="relative  h-screen rounded-3xl">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-3/4 md:h-5/6 transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundImage: `url(${image.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2.1875rem",
          }}
        >
          <div className="absolute bottom-20 left-14 flex  text-black">
            <h1
              className={`text-3xl font-bold text-[#fff] ${images.textColor}`}
              dangerouslySetInnerHTML={{
                __html: image.text.replace(/\n/g, "<br>"),
              }}
            ></h1>
          </div>
        </div>
      ))}

      <div className="absolute bottom-40 right-1/3 md:bottom-6 md:right-20 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-4 h-4 bg-gray-300 rounded-full ${
              index === currentIndex ? "bg-green-500" : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HeroSlideshow;
