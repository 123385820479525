import React from "react";
import phoneGreen from "../assets/phone-green.svg";

const ContactUs = () => {
  const phoneNumber = "0904 858 3593";

  const handlePhoneClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      alert("Dial " + phoneNumber);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-16 mt-52 mb-36 text-[#545454]">
      <article className="flex flex-col gap-6 justify-center items-center">
        <p className="text-4xl font-bold capitalize text-center">
          Get <span className="text-[#00D959]">efficient</span> & Affordable
          power
        </p>
        <p className="text-center text-xl font-semibold">
          Overall, our clean energy solar company is dedicated to making solar{" "}
          <br className="hidden lg:inline" />
          energy accessible, affordable, and reliable for everyone in Ibadan
        </p>
      </article>
      <article className="flex flex-col gap-6 items-center justify-center">
        <div
          className="flex items-center text-[#00D959] text-2xl lg:text-4xl font-semibold gap-4 cursor-pointer"
          onClick={handlePhoneClick}
        >
          <img src={phoneGreen} alt="" />
          <p>{phoneNumber}</p>
        </div>
        <p className="text-[#ADADAD] text-xl font-semibold">
          Available on Whatsapp
        </p>
      </article>
    </div>
  );
};

export default ContactUs;
