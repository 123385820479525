import React from "react";

const Values = () => {
  return (
    <div className="flex flex-col gap-14 items-center justify-center text-[#545454] mb-48">
      <header className="text-4xl font-bold">Our Values</header>
      <main className="flex flex-col gap-4  font-semibold text-base">
        <p>
          Our clean energy solar company in Ibadan is built on strong values
          that guide everything <br className="hidden lg:inline" /> we do. We
          believe in creating a more sustainable future through solar energy
          solutions <br className="hidden lg:inline" /> that are innovative,
          honest, and of the highest quality.
        </p>{" "}
        <p>
          We strive for excellence in everything we do, from design and
          installation to maintenance <br className="hidden lg:inline" /> and
          support, and we collaborate with our customers, partners, and
          community to create <br className="hidden lg:inline" /> a
          solar-powered ecosystem that benefits everyone.
        </p>{" "}
        <p>
          Our values inspire us to create a better future for ourselves, our
          community, and the <br className="hidden lg:inline" /> planet, and
          we're committed to making solar energy accessible to everyone in
          Ibadan.
        </p>
      </main>
    </div>
  );
};

export default Values;
