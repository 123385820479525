import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import "./index.css";

function App() {
  return (
    <div className="">
      <Header />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
