import React from "react";
import logoText from "../assets/Logo-text.svg";
import phone from "../assets/phone.svg";

const Header = () => {
  const phoneNumber = "0904 858 3593";

  const handlePhoneClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      alert("Dial " + phoneNumber);
    }
  };

  return (
    <div className="px-8 lg:px-36 py-3 lg:py-5 flex justify-between items-center shadow-sm">
      <img src={logoText} alt="" className="w-28 lg:w-auto " />
      <div
        className="flex items-center gap-1 lg:gap-3 cursor-pointer"
        onClick={handlePhoneClick}
      >
        <img src={phone} alt="" />
        {/* <p className="text-[#848484] text-lg  font-semibold">{phoneNumber}</p> */}
      </div>
    </div>
  );
};

export default Header;
