import React from "react";
import footerImg from "../assets/footer-img.svg";
import phoneBlue from "../assets/phone-blue.svg";
import location from "../assets/map-pin.svg";
import facebook from "../assets/socials/facebook.svg";
import instagram from "../assets/socials/instagram.svg";
import twitter from "../assets/socials/twitter.svg";

const Footer = () => {
  const phoneNumber = "0904 858 3593";

  const handlePhoneClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      window.location.href = "tel:" + phoneNumber;
    } else {
      alert("Dial " + phoneNumber);
    }
  };

  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-[#FCFCFC]">
      <div className="px-4 lg:px-20 flex flex-col gap-8 text-center lg:text-start lg:gap-14 justify-center lg:justify-normal ">
        <main className="flex justify-center items-center  lg:justify-between lg:items-center">
          <article className="flex flex-col gap-2 ">
            <p className="text-[#1A1D37] text-3xl lg:text-4xl font-bold">
              Lunge Energy
            </p>
            <p className="capitalize font-semibold text-[#353251]">
              Bringing Sustainable and Affordable <br /> Solar Energy to The
              World
            </p>
          </article>
          <img src={footerImg} alt="" className="hidden lg:block" />
        </main>
        <main className="flex flex-col gap-6 lg:gap-4 lg:flex-row  lg:justify-between">
          <div className="flex justify-center items-center gap-2">
            <div className="bg-[#4402FF] w-2 h-2 rounded-full mr-5"></div>
            <img
              src={phoneBlue}
              alt=""
              onClick={handlePhoneClick}
              className="cursor-pointer"
            />
            <p
              className="text-[#4402FF] font-semibold text-2xl cursor-pointer "
              onClick={handlePhoneClick}
            >
              {phoneNumber}
            </p>
          </div>
          <div className="flex items-start lg:gap-3">
            <img src={location} alt="" />
            <p className="text-[#515151] text-base lg:text-lg font-semibold capitalize">
              Rofab Aluminium Complex 15,16 Opp Amos{" "}
              <br className="hidden lg:inline" /> Olu Sango Eleyele Road Ibadan
            </p>
          </div>
          <div className="flex items-center justify-center lg:justify-normal gap-4 lg:gap-3">
            <p className="text-[#515151] font-semibold text-lg lg:text-xl mr-1">
              Follow us
            </p>
            <img
              src={facebook}
              alt=""
              className="cursor-pointer image-color "
            />
            <img
              src={instagram}
              alt=""
              className="cursor-pointer image-color"
            />
            <img src={twitter} alt="" className="cursor-pointer image-color" />
          </div>
        </main>
      </div>
      <main className="flex items-center justify-center bg-[#EBEBEB] py-4 mt-4 lg:mt-14 rounded-b-3xl">
        <p className="text-[#515151] font-bold text-xs lg:text-sm uppercase tracking-wide">
          {" "}
          LUNGE ENERGY LTD. <span className="ml-4"></span> ©{currentYear}
        </p>
      </main>
    </div>
  );
};

export default Footer;
