import React from "react";
import lungeLogoGreen from "../assets/logo-green.svg";
import HeroSlideshow from "../helpers/HeroSlideshow";
import lungeLogoPlain from "../assets/logo-plain.svg";
import infinity from "../assets/infinity.svg";
import image from "../assets/hero-section-community.jpg";
import imageCommunity from "../assets/hero-section-community-mobile.jpg";
import Values from "../helpers/Values";
import Feedbacks from "../helpers/Feedbacks";
import ContactUs from "../helpers/ContactUs";

const Hero = () => {
  return (
    <div className=" px-6 lg:px-36">
      <div className="flex flex-col items-center gap-4 mt-24 lg:mt-36 mb-24 text-center text-[#545454]">
        <img src={lungeLogoGreen} alt="" />
        <p className=" text-base font-semibold capitalize">Lunge Energy</p>
        <p className=" text-3xl font-black capitalize mt-3">
          clean solar energy <br /> with flexible Payments.
        </p>
        <p className="text-sm font-semibold capitalize">
          harnessing the abundant energy of the sun, we can create a cleaner and{" "}
          <br />
          more sustainable future for ourselves and for generations to come.{" "}
        </p>
      </div>
      <div className="">
        <HeroSlideshow />
      </div>
      <article className="flex flex-col items-center justify-center mt-8 lg:mt-12 text-[#545454] font-semibold ">
        <div className="flex gap-4 lg:gap-32 mb-16">
          <div className="flex items-center gap-2 lg:gap-6">
            <img src={infinity} alt="" />
            <p>Hybrid System</p>
          </div>
          <div className="flex items-center gap-2 lg:gap-6">
            <img src={lungeLogoPlain} alt="" />
            <p>Single System</p>
          </div>
        </div>
        <p className="mb-4 capitalize">Established in September 2019 </p>
        <p className="text-center">
          We grew 3 Million naira in revenue as at 2019, Processed 10 million in
          2020 , <br /> grew revenue by 33% to 13 million in 2021,Increased
          revenue by 150% to <br /> NGN24.2 million to till sate
        </p>
      </article>
      <main className="flex flex-col lg:flex-row text-[#000] gap-7 justify-center my-32 px-6 lg:px-0">
        <div className="flex flex-col w-full  lg:w-1/3 border border-[#EEE] bg-[#F8F8F8] rounded-[2.1875rem] px-24 py-28 items-center gap-2">
          <p className="font-semibold text-6xl">10+</p>
          <p className="font-semibold text-xl">Projects</p>
        </div>
        <div className="flex flex-col w-full  lg:w-1/3 border border-[#EEE] bg-[#F8F8F8] rounded-[2.1875rem] px-24 py-28 items-center gap-2">
          <p className="font-semibold text-6xl">5+</p>
          <p className="font-semibold text-xl text-center">
            Communities <br />
            Impacted
          </p>
        </div>
        <div className="flex flex-col w-full  lg:w-1/3 border border-[#EEE] bg-[#F8F8F8] rounded-[2.1875rem] px-16 py-28 items-center gap-2">
          <p className="font-semibold text-6xl">70KVa</p>
          <p className="font-semibold text-xl text-center">
            KVA of Renewable <br /> Energy Delivered
          </p>
        </div>
      </main>
      <div className="relative h-screen lg:hidden">
        <div
          className="h-5/6 px-3 flex items-end justify-center pb-20"
          style={{
            backgroundImage: `url(${imageCommunity})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2.1875rem",
          }}
        >
          <div className=" bg-[#fff] rounded-xl pt-12 pb-14 px-6 flex flex-col gap-4 text-[#545454] capitalize">
            <p className="font-bold text-xl">
              Empowering local <br /> community to sustainable <br />
              green energy
            </p>
            <p className="font-semibold text-sm">
              Providing local community access <br /> to flexible payment plan
              for <br /> renewable energy
            </p>
          </div>
        </div>
      </div>
      <div className="relative h-screen hidden lg:block">
        <div
          className="h-5/6"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2.1875rem",
          }}
        >
          <div className="absolute bottom-1/3 left-0 lg:bottom-40 lg:left-16 bg-[#fff] rounded-xl pt-12 pb-14 pl-10 pr-24 flex flex-col gap-4 text-[#545454] capitalize">
            <p className="font-bold text-2xl">
              Empowering local community <br /> to sustainable green energy
            </p>
            <p className="font-semibold text-base">
              Providing local community access <br /> to flexible payment plan
              for renewable energy
            </p>
          </div>
        </div>
      </div>
      <Values />
      <Feedbacks />
      <ContactUs />
    </div>
  );
};

export default Hero;
